<template>
    <div>
        <div class="timer">
          <span> {{ countdownTime.hours }} : {{ countdownTime.mins }} : {{ countdownTime.secs }} </span>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('staging/test');
export default {
  name: 'CountDownTimer',
  data() {
    return {
      timer: null,
      timeElapsed: false,
      timeRemaining: 0,
      timeSpent: 0,

    };
  },
  props: {
    notifyTimeRemaining: { type: Array, default: () => [] },
    duration: { type: Number, default: null },
    userDone: { type: Boolean, default: false },
  },
  methods: {
    checkIfTimeHasElapsed() {
      if (this.timeRemaining === 0) {
        this.timeElapsed = true;
      }
    },
    checkTimeRemaining() {
      const timeRemainingArray = this.notifyTimeRemaining;
      if (timeRemainingArray.includes(this.timeRemaining)) {
        this.$emit('timeRemaining', [this.timeRemaining, this.timeSpent]);
      }
    },
    countdown() {
      this.checkIfTimeHasElapsed();
      this.checkTimeRemaining();
      if (this.timeElapsed) {
        this.stopTimer();
      } else if (this.userDone) {
        this.stopTimer();
        this.$emit('userDone', this.timeSpent);
      } else {
        this.timeRemaining = this.timeRemaining - 1;
        this.timeSpent = this.duration - this.timeRemaining;
      }
    },
    startCountdown() {
      this.timer = setInterval(() => this.countdown(), 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  computed: {
    ...mapGetters(['timeAllowed']),
    countdownTime() {
      // calculate hours
      let hours = Math.floor(this.timeRemaining / 3600);

      // claculate mins
      let remaningSeconds = this.timeRemaining - (hours * 3600);
      let mins = Math.floor(remaningSeconds / 60);

      // calcuate seconds
      remaningSeconds = this.timeRemaining - (mins * 60);
      let secs = Math.ceil(remaningSeconds % 60);

      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (mins < 10) {
        mins = `0${mins}`;
      }
      if (mins > 59) {
        mins = '00';
      }
      if (secs < 10) {
        secs = `0${secs}`;
      }
      if (secs > 59) {
        secs = '00';
      }

      return { hours, mins, secs };
    },
  },
  mounted() {
    this.timeRemaining = this.duration;
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style lang='stylus' scoped>
</style>
